import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import PriceSection from '../components/pageSections/Price/index'
import TrialSection from '../components/pageSections/Trial/index'

const TrialApplicationPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="料金表　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="モバプロの受講料金を紹介します。ただいま特別料金でご案内中ですので、お気軽にお問い合わせください。"
      pathName={pathname}
    />
    <PriceSection />
    <TrialSection />
  </Layout>
)

TrialApplicationPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

TrialApplicationPage.defaultProps = {
  location: null,
}

export default TrialApplicationPage
