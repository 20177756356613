import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Section, InnerMedium } from '../../Sections'
import color from '../../../constants/colors'

const InnerStyled = styled(InnerMedium)`
  max-width: 640px;
  padding: 0 40px 80px;
  padding-bottom: ${(props) => (props.isTrialPage ? '30px' : '80px')};

  ${media.lessThan('medium')`
    padding: 0 40px 50px;
  `}
`

const Container = styled.div`
  border: 1px solid ${color.blue};
  background-color: ${color.lightBlue};
  font-family: "Hiragino Mincho ProN", 游明朝, "Yu Mincho", YuMincho, メイリオ, Meiryo, serif;
  padding: 30px 50px 50px;

  ${media.lessThan('medium')`
    padding: 20px 20px 30px;
  `}
`

const Heading = styled.h3`
  color: ${color.heading};
  font-size: 2.5rem;
  margin: 20px 0 45px;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 2.2rem;
  `}
`

const ColordHeading = styled.span`
  color: ${color.main};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  margin-left: 15px;

  ${media.lessThan('medium')`
    display: block;
    margin-left: 0;
    margin-top: 10px;
  `}
`

const Item = styled.li`
  color: ${color.text};
  font-size: 2rem;
  margin: 5px;

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

const Annotaion = styled.p`
  font-size: 1.6rem;
  margin-left: 30px;
`

const Card = ({ isTrialPage }) => (
  <Section id="trial-card">
    <InnerStyled isTrialPage={isTrialPage}>
      <Container>
        <Heading>
          体験レッスン（40分）
          <ColordHeading>550円</ColordHeading>
        </Heading>
        <ol>
          <Item>
            イントロダクション
          </Item>
          <Item>
            スピーキングチェック
          </Item>
          <Item>
            パターンプラクティスと瞬間英作文の体験
          </Item>
          <Item>
            カウンセリング
          </Item>
        </ol>
      </Container>
      <Annotaion>※料金は税込価格です。</Annotaion>
      <Annotaion>※体験レッスン料金は受講前の場合は返金致します。</Annotaion>
    </InnerStyled>
  </Section>
)

Card.propTypes = {
  isTrialPage: PropTypes.bool,
}

Card.defaultProps = {
  isTrialPage: false,
}

export default Card
