import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import color from '../../../constants/colors'

const ButtonStyled = styled(Link)`
  transition: all .3s;
  background-color: ${color.main};
  color: white;
  display: block;
  font-size: 2rem;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  max-width: 500px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;

  &.sal-animate {
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation-delay: .3s;
  }

  ${media.lessThan('medium')`
    font-size: 1.8rem;
    padding: 0 10px;
  `}

  &:hover {
    background-color: ${color.mainHighlighted};
  }

  @keyframes scale-up-center {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Button = () => (
  <ButtonStyled to="/trial#inquiry-form" data-sal data-sal-once>
    体験レッスンを申し込む
  </ButtonStyled>
)

export default Button
