import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Section, InnerLarge } from '../../Sections'
import color from '../../../constants/colors'
import Body from './Body'
import Card from './Card'
import Button from './Button'

const SectionStyled = styled(Section)`
  background-image: url('/image/trial/headset.jpg');
  background-position: center;
  background-size: cover;
`

const InnerStyled = styled(InnerLarge)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 420px;

  ${media.lessThan('medium')`
    height: 200px;
  `}
`

const HeadingWrapper = styled.div`
  background-color: rgb(255, 255, 255, 0.6);
  padding: 80px 160px;

  ${media.lessThan('medium')`
    padding: 20px 40px;
  `}
`

const Heading = styled.h2`
  color: ${color.heading};
  display: inline-block;
  font-family: "Hiragino Mincho ProN", 游明朝, "Yu Mincho", YuMincho, メイリオ, Meiryo, serif;
  font-size: 4rem;

  ${media.lessThan('medium')`
    font-size: 2.6rem;
  `}
`

const ButtonArea = styled.div`
  padding: 0 30px 100px;

  ${media.lessThan('medium')`
    padding-bottom: 80px;
  `}
`

const Trial = ({ isTrialPage }) => (
  <>
    <SectionStyled wrapperColor={color.dark}>
      <InnerStyled>
        <HeadingWrapper>
          <Heading>
            体験レッスンのご案内
          </Heading>
        </HeadingWrapper>
      </InnerStyled>
    </SectionStyled>
    <Body />
    <Card
      isTrialPage={isTrialPage}
    />
    {!isTrialPage && (
      <ButtonArea>
        <Button />
      </ButtonArea>
    )}
  </>
)

Trial.propTypes = {
  isTrialPage: PropTypes.bool,
}

Trial.defaultProps = {
  isTrialPage: false,
}

export default Trial
