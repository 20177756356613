import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Section, InnerSmall } from '../../Sections'
import color from '../../../constants/colors'

const InnerStyled = styled(InnerSmall)`
  padding: 80px 40px 50px;

  ${media.lessThan('medium')`
    padding: 40px 40px 30px;
  `}
`

const Text = styled.p`
  color: ${color.text};
`

const Body = () => (
  <Section>
    <InnerStyled>
      <Text>
        スピーキングテストを含む40分間の授業をワンコイン（550円・消費税込み）でお試し頂けます。
      </Text>
      <Text>
        日本人講師が対話をしながら行うスピーキングテストにより、英語力の上達に欠かせない現状の英語力の正しい把握をすることができます。
        発音・イントーネーション・瞬発力（応答の速さ）・時制の使い分け・質問文の作成力・間違いやすさの傾向など細かくレポートいたしますので、今後の英語学習に役立つこと間違いなしです！
      </Text>
      <Text>
        また、モバプロの特徴である「会話文の作り方を教授する」ためのメソッドのパターンプラクティス・瞬間英作文をご体験いただけます。
      </Text>
      <Text>
        授業の終わりには今後の英語学習の相談などしていただけるカウンセリングのお時間もございますので、
        日本人講師に英語上達に関する疑問などお気軽にお聞きください。
      </Text>
    </InnerStyled>
  </Section>
)

export default Body
